import bgImageUrl from "../../assets/images/Waermewende_Animation_Hochformat.gif";
import { HTMLAttributes, ReactElement } from "react";

export type LeftPaneProps = HTMLAttributes<HTMLElement> & {
  title?: string;
  intro?: string;
};

const LeftPane = ({ title, intro }: LeftPaneProps): ReactElement => {
  return (
    <section className="w-full max-w-[25%] dds-product-intro dds-grow dds-flex dds-flex-col dds-relative dds-text-white">
      {/** background image */}
      <div className="dds-absolute dds-inset-0">
        <img
          alt="background_image"
          src={bgImageUrl}
          className="dds-w-full dds-h-full dds-object-cover"
        />
      </div>
    </section>
  );
};

export default LeftPane;
