import Footer from "../components/sections/footer";
import { HTMLAttributes, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import LeftPane from "../components/sections/left-pane";
import logoIcon from "../assets/images/digikoo-logo-dark.png";

export type StartLayoutProps = HTMLAttributes<HTMLDivElement>;

const StartLayout = ({ children }: StartLayoutProps): ReactElement => {
  const { t } = useTranslation();
  return (
    <div className="min-h-screen flex flex-col min-w-screen">
      <div className="grow flex">
        <LeftPane />

        <div className="flex flex-col grow">
          <div className="absolute top-10 right-10">
            <img className="w-[74px]" src={logoIcon} alt="digikoo_logo" />
          </div>

          <div className="grow px-20 py-20 flex justify-center items-center">
            {children}
          </div>

          <div className="footer">
            <Footer
              className="w-full"
              theme="dark"
              copy={`©${t("footer.copy")}`}
              items={t("footer.items", { returnObjects: true }) as any}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartLayout;
